import * as React from 'react';
import {useEffect, useState} from 'react';
import {Navigation} from '../components/navigation';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import './docs.scss';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import syntaxStyle from 'react-syntax-highlighter/dist/esm/styles/prism/base16-ateliersulphurpool.light';

export default function Component() {

    if (typeof window === 'undefined') {
        return;
    }

    const [content, setContent] = useState('');
    const [menu] = useState({
        title: 'Documentation',
        href: '/docs',
        children: [
            {
                title: 'Overview',
                href: '#overview'
            },
            {
                title: 'General Architecture',
                href: '#general-architecture',
                children: [
                    {
                        title: 'Why do we decouple?',
                        href: '#why-decoupling',
                    },
                    {
                        title: 'Composition/package creation process',
                        href: '#process',
                    },
                    {
                        title: 'Localisation and translation',
                        href: '#localisation',
                    },
                    {
                        title: 'Styling',
                        href: '#styling',
                    },
                    {
                        title: 'Preview and live environments',
                        href: '#preview-live',
                    },
                    {
                        title: 'Versionning',
                        href: '#versionning',
                    },
                    {
                        title: 'Regulatory compliance',
                        href: '#compliance',
                    },
                    {
                        title: 'Security and package integrity',
                        href: '#integrity',
                    },
                    {
                        title: 'Patient privacy',
                        href: '#privacy',
                    },
                    {
                        title: 'Definitions',
                        href: '#definitions',
                    },
                ]
            },
            {
                title: 'Demo',
                href: '#demo',
            },
            {
                title: 'Installation',
                href: '#installation',
                children: [
                    {
                        title: 'Basic bundle',
                        href: '#basic-bundle',
                    },
                    {
                        title: 'NPM',
                        href: '#npm',
                        children: [
                            {
                                title: 'Content bundle',
                                href: '#npm-content',
                            },
                            {
                                title: 'Loader bundle',
                                href: '#npm-loader',
                                children: [
                                    {
                                        title: 'copy-content',
                                        href: '#npm-loader-copy-content',
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Examples',
                href: '#examples',
                children: [
                    {
                        title: 'ES example',
                        href: '#examples-es',
                    },
                    {
                        title: 'UMD example',
                        href: '#examples-umd',
                    },
                    {
                        title: 'Framework integration',
                        href: '#examples-framework-integration',
                    },
                ]
            },
            {
                title: 'Configuration',
                href: '#configuration',
                children: [
                    {
                        title: 'Container',
                        href: '#container',
                    },
                    {
                        title: 'Options',
                        href: '#options',
                        children: [
                            {
                                title: 'target',
                                href: '#options-target',
                            },
                            {
                                title: 'baseUrl',
                                href: '#options-base-url',
                            },
                            {
                                title: 'configurationUrl',
                                href: '#options-configuration-url',
                            },
                            {
                                title: 'modules',
                                href: '#options-modules',
                            },
                            {
                                title: 'module',
                                href: '#options-module',
                            },
                            {
                                title: 'events',
                                href: '#options-events',
                            },
                            {
                                title: 'onMounted',
                                href: '#options-on-mounted',
                            },
                            {
                                title: 'onBeforeMounted',
                                href: '#options-on-before-mounted',
                            },
                            {
                                title: 'onError',
                                href: '#options-on-error',
                            }
                        ]
                    },
                    {
                        title: 'Unmount',
                        href: '#unmount',
                    },
                    {
                        title: 'Events',
                        href: '#events',
                        children: [
                            {
                                title: 'formControlChange',
                                href: '#events-form-control-change',
                            },
                            {
                                title: 'multiStepFormSubmit',
                                href: '#events-multi-step-form-submit',
                            },
                            {
                                title: 'formSubmit',
                                href: '#events-form-submit',
                            },
                            {
                                title: 'injectFormData',
                                href: '#events-form-data-inject',
                            }
                        ]
                    },
                ]
            },
            {
                title: 'TypeScript',
                href: '#typescript',
                children: [
                    {
                        title: 'Module',
                        href: '#typescript-module',
                    },
                    {
                        title: 'Event',
                        href: '#typescript-event',
                    },
                    {
                        title: 'Answer',
                        href: '#typescript-answer',
                    },
                    {
                        title: 'Configuration',
                        href: '#typescript-configuration',
                    },
                    {
                        title: 'Loader',
                        href: '#typescript-loader',
                    }
                ]
            },
            {
                title: 'Styling',
                href: '#styling',
                children: [
                    {
                        title: 'CSS variables',
                        href: '#styling-css-variables',
                    },
                    {
                        title: 'Common variables',
                        href: '#styling-common-css-variables',
                    },
                    {
                        title: 'Component variables',
                        href: '#styling-component-css-variables',
                    }
                ]
            },
        ]
    });

    useEffect(() => {
        fetch('/docs.md').then(async (res) => {
            const md = await res.text();
            setContent(md);
        });
    }, []);

    useEffect(() => {
        const anchors = document.querySelectorAll('.docs-content a');
        anchors.forEach((anchor) => {
            const hash = anchor.getAttribute('href');
            if (hash.indexOf('#') > -1 && hash !== '#') {
                anchor.setAttribute('href', '#');
                anchor.setAttribute('id', hash.replace('#', ''));
                anchor.setAttribute('onclick', 'return false');
            }
        });
    }, [content]);

    setTimeout(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, 2000);

    return (
        <div className="docs">
            <Navigation currentPage={'docs'}/>
            <roche-grid layout="12">
                <div className="grid">
                    <div className="menu-sidebar">
                        <MenuComponent menu={menu}/>
                    </div>
                    <div className="docs-content">
                        <ReactMarkdown components={{
                            code({node, inline, className, children, ...props}) {
                                const match = /language-(\w+)/.exec(className || '')
                                return !inline && match ? (
                                    <SyntaxHighlighter
                                        {...props}
                                        children={String(children).replace(/\n$/, '')}
                                        useInlineStyles={false}
                                        style={syntaxStyle}
                                        language={match[1]}
                                        PreTag="div"
                                    />
                                ) : (
                                    <code {...props} className={className}>
                                        {children}
                                    </code>
                                )
                            }
                        }} remarkPlugins={[remarkGfm]}>
                            {content}
                        </ReactMarkdown>
                    </div>
                </div>
            </roche-grid>
        </div>
    );
}


function MenuComponent({menu}) {

    const toggleSubmenu = (e, rootClass) => {
        e.preventDefault();
        const el = document.querySelector(`.${rootClass}-menu`);
        const titleEl = document.querySelector(`.title-${rootClass}`);
        const openEl = document.querySelector(`.${rootClass}-open`);
        const closeEl = document.querySelector(`.${rootClass}-close`);
        if (el.classList.contains('collapse')) {
            el.classList.remove('collapse');
            titleEl.classList.remove('collapse');
            openEl.classList.remove('hide');
            closeEl.classList.add('hide');
        } else {
            el.classList.add('collapse');
            titleEl.classList.add('collapse');
            openEl.classList.add('hide');
            closeEl.classList.remove('hide');
        }
    }

    const rootClass = menu.href.replace('#', '');
    return menu.children && <>
        <div className={`ul-title title-${rootClass}`}>
            <a href={`${menu.href}`}>
                <div className="with-toggle">
                    {menu.title}
                    {rootClass !== '/docs' ? (<>
                        <div className={`${rootClass}-open`} onClick={(e) => toggleSubmenu(e, rootClass)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                                <line className="cls-1" x1="36" y1="96" x2="156" y2="96"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="8px"
                                      stroke="#0B41CD"/>
                            </svg>
                        </div>
                        <div className={`${rootClass}-close hide`} onClick={(e) => toggleSubmenu(e, rootClass)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                                <line className="cls-1" x1="36" y1="96" x2="156" y2="96"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="8px"
                                      stroke="#0B41CD"/>
                                <line className="cls-1" x1="96" y1="36" x2="96" y2="156"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="8px"
                                      stroke="#0B41CD"/>
                            </svg>
                        </div>
                    </>) : <></>}
                </div>
            </a>
        </div>
        <ul className={`${rootClass} ${rootClass}-menu`}>
            {menu.children.map((item, index) => {
                if (item.children && item.children.length > 0) {
                    return <li key={index}><MenuComponent menu={item} key={index}/></li>
                } else {
                    return <li key={index}><a href={`${item.href}`}>{item.title}</a></li>
                }
            })}
        </ul>
    </>;
}
